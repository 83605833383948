/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require('./src/components/prism.css');
const ReactGA = require('react-ga');

ReactGA.initialize('UA-165816694-1', {
  debug: true,
});

// create a global react ga object
window.ReactGA = ReactGA;

exports.onRouteUpdate = (state, page, pages) => {
  ReactGA.pageview(state.location.pathname);
};

// You can delete this file if you're not using it
